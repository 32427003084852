@import "variables";

@media (max-width: $bp-small) {
  body {
    padding: 70px 0;
    font-size: 13px;
  }
  body.checkout {
    padding-top: 125px;
  }
  hr {
    margin: 5px 0 15px;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 13px;
  }
  h3.border {
    font-size: 24px;
    margin: 35px 0 0 0;
    padding: 18px 0 30px;
  }
  h3.border:after {
    width: 118px;
    height: 3px;
    margin-left: -59px;
  }
  h4 {
    font-size: 18px;
  }
  h4.margin {
    margin-bottom: 18px;
    font-size: 18px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 12px;
  }
  p {
    font-size: 13px;
  }
  form .form-group {
    margin-bottom: 15px;
  }
  form p.help-block {
    line-height: 9px;
    font-size: 8px;
  }
  form a.help-block {
    margin-top: 5px;
    line-height: 12px;
    font-size: 8px;
  }
  form label {
    line-height: 15px;
    font-size: 10px;
  }
  form label.radio-inline {
    line-height: 22px;
    margin-top: 4px !important;
    font-size: 10px;
  }
  form label.checkbox-inline {
    line-height: 22px;
    font-size: 8px;
  }
  form input[type="text"],
  form input[type="email"],
  form input[type="password"],
  form input[type="number"],
  form select {
    height: 30px;
    line-height: 15px;
    padding: 9px 18px 6px;
    font-size: 10px;
  }
  .btn {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border-width: 2px;
    font-size: 11px;
  }
  .container {
    width: 320px;
  }
  .container.small {
    width: 320px;
    padding: 10px 0;
  }
  .nav-tabs {
    padding: 0 5px;
  }
  .nav-tabs li a {
    height: 30px;
    line-height: 31px;
    font-size: 10px;
  }
  .modal .modal-dialog {
    width: 310px;
    margin: 150px 0 50px 0;
  }
  .modal .modal-dialog.big {
    width: 310px;
  }
  .modal .modal-content .modal-header {
    height: 30px;
    line-height: 32px;
    font-size: 13px;
  }
  .modal .modal-content .modal-header .close {
    width: 20px;
    height: 420px;
    top: -30px;
    right: 7px;
  }
  .modal .modal-content .modal-header .close:before {
    width: 24px;
    height: 24px;
    border-right: #fff 1px solid;
    top: -12px;
    left: -12px;
  }
  .modal .modal-content .modal-header .close:after {
    width: 24px;
    height: 24px;
    border-right: #fff 1px solid;
    top: 4px;
    left: -11px;
  }
  #img-header .img {
    height: 235px;
  }
  #img-header .text-header {
    height: 145px;
    padding: 25px;
  }
  #img-header .text-header p {
    font-size: 30px;
    line-height: 30px;
    padding: 0 25px;
  }
  #img-header .center-title-lojas {
    padding-top: 100px !important;
  }
  .central-title {
    margin: 0 auto;
  }
  .central-title h2 span {
    line-height: 1.2;
  }
  .central-title h2 span:after {
    width: calc((310px - 100%) / 2);
    top: 8px;
    right: calc((-320px + 100%) / 2);
  }
  .central-title h2 span:before {
    width: calc((310px - 100%) / 2);
    top: 8px;
    left: calc((-320px + 100%) / 2);
  }
  .central-title .description {
    font-size: 13px;
    line-height: 14px;
    margin: 15px auto;
  }
  .btn-link {
    font-size: 10px;
  }
  .btn-link.black {
    font-size: 10px;
  }
  .navbar {
    height: 90px !important;
  }
  .navbar .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar.navbar-default {
    border-width: 3px;
    border-left: 0;
    border-right: 0;
  }
  .navbar:after {
    background: #ae1f6f !important;
    height: 3px !important;
    bottom: -6px !important;
  }
  .navbar .open-menu {
    width: 32px;
    height: 24px;
    padding: 5px;
    margin: 32px 0 0 0 !important;
  }
  .navbar .open-menu .bar {
    width: 22px;
    height: 2px;
    margin-top: 4px;
    background: #513593;
  }
  .navbar .open-menu .bar:first-child {
    margin: 0;
  }
  .navbar .open-menu.open .bar:nth-child(1) {
    width: 27px;
    margin: 6px 0 0 -1px;
  }
  .navbar .open-menu.open .bar:nth-child(2) {
    width: 27px;
    margin: -2px 0 0 -2px;
  }
  .navbar .logo {
    width: auto !important;
    height: 64px !important;
    margin: 9px 0 5px -80px !important;
    left: 50%;
  }
  .navbar .logo img {
    width: auto !important;
    height: 64px !important;
  }
  .navbar.single .logo {
    margin-left: -43px !important;
  }
  .navbar .right {
    padding-top: 17px;
    margin: 0 10px 0 0;
  }
  .navbar .right .fav {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
  }
  .navbar .right .bag {
    margin: 10px 0 0 20px;
  }
  .navbar .right .bag .icon {
    width: 22px;
    height: 27px;
    background-size: cover;
  }
  .navbar .right .bag .count {
    width: 22px;
    height: 21px;
    line-height: 22px;
    top: 16px;
    right: -8px !important;
    margin: 0 !important;
    font-size: 12px;
  }
  .navbar .steps {
    padding: 15px 0;
    top: 70px;
  }
  .navbar .steps ul li:after {
    top: 8px;
  }
  .navbar .steps ul li .number {
    width: 17px;
    height: 17px;
    line-height: 17px;
    margin-bottom: 8px;
    font-size: 10px;
  }
  .navbar .steps ul li .number:after {
    width: 4px;
    height: 4px;
    margin: -2px 0 0 -2px;
  }
  .navbar .steps ul li .text {
    font-size: 7px;
  }
  #menu-mobile {
    height: calc(100% - 78px) !important;
    top: 90px !important;
  }
  #menu-mobile.open {
    max-height: 800px;
  }
  #menu-mobile form {
    padding: 25px 23px;
  }
  #menu-mobile form input {
    width: calc(100% - 34px);
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
    height: 30px;
    border: 0;
    font-size: 12px;
  }
  #menu-mobile form button {
    width: 34px;
    height: 30px;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    background-size: cover;
  }
  #menu-mobile > ul > li > a {
    line-height: 23px;
    padding: 12px 23px 9px;
    font-size: 17px;
  }
  #menu-mobile > ul > li.has-submenu > a:after {
    border-width: 5px 0 5px 6px;
    margin-top: -5px;
    right: 23px;
  }
  #menu-mobile > ul > li.has-submenu .submenu {
    width: 100%;
    height: calc(100% - 61px);
    top: 61px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .voltar {
    padding: 15px 45px 12px;
    font-size: 12px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .voltar:before {
    margin: -10px 0 0 -5px;
    border-width: 10px 10px 10px 0;
    left: 25px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .voltar:after {
    margin: -10px 0 0 -5px;
    border-width: 10px 10px 10px 0;
    left: 26px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .link {
    line-height: 23px;
    padding: 12px 23px 9px;
    font-size: 17px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .link:after {
    border-width: 6px 5px 0 5px;
    margin-top: -5px;
    right: 23px;
  }
  #menu-mobile > ul > li.has-submenu .submenu p {
    padding: 25px 23px 15px;
    font-size: 17px;
  }
  #menu-mobile > ul > li.has-submenu .submenu ul li a {
    height: 40px;
    line-height: 20px;
    padding: 12px 40px 8px;
    font-size: 14px;
  }
  #menu-mobile > ul > li.has-submenu .submenu ul li a:after {
    width: 20px;
    height: 2px;
    left: 12px;
  }
  #menu-mobile > ul > li.institucional ul {
    padding: 10px 0;
  }
  #menu-mobile > ul > li.institucional ul li a {
    height: 40px;
    line-height: 20px;
    padding: 12px 40px 8px;
    font-size: 12px;
  }
  #menu-mobile > ul > li.institucional ul li a:after {
    width: 20px;
    height: 2px;
    left: 12px;
  }
  #menu-mobile > ul > li.logado span {
    font-size: 18px;
  }
  .navbar-fixed-top.checkout {
    position: relative !important;
    margin-bottom: 60px;
  }
  .navbar-fixed-top.checkout .container {
    padding: 0 !important;
  }
  body.checkout {
    padding-top: 0 !important;
  }
  .navbar .steps {
    clear: both;
    top: 0 !important;
    position: relative !important;
  }
  .navbar .steps:before,
  .navbar .steps:after {
    display: table;
    content: "";
  }
  .navbar .steps:after {
    clear: both;
  }
  .navbar .steps ul:before,
  .navbar .steps ul:after {
    display: table;
    content: "";
  }
  .navbar .steps ul:after {
    clear: both;
  }

  #footer {
    padding: 297px 0 0 0;
  }
  #footer .formas-pagamento {
    border-width: 0;
    padding-top: 20px;
    border-top: none !important;
  }
  #footer .formas-pagamento h5 {
    padding: 0 29px;
  }
  #footer .formas-pagamento p {
    font-size: 12px;
    line-height: 15px;
    padding: 0 29px;
  }
  #footer .formas-pagamento ul {
    padding: 0;
    margin: 10px 0 0 0;
  }
  #footer .formas-pagamento ul li {
    width: 48px;
    height: 21px;
    background-size: 240px auto;
  }
  #footer .formas-pagamento ul li.visa {
    background-position: -48px 0;
  }
  #footer .formas-pagamento ul li.american-express {
    background-position: -96px 0;
  }
  #footer .formas-pagamento ul li.boleto {
    background-position: -144px 0;
  }
  #footer .formas-pagamento ul li.onebuy {
    background-position: -192px 0;
  }
  #footer .entrega {
    padding: 15px 29px 0 29px;
  }
  #footer .entrega h5 {
    padding-left: 0;
    padding-bottom: 3px;
    margin-bottom: 20px;
    padding-top: 15px;
  }
  #footer .entrega ul li {
    margin: 8px 0;
  }
  #footer .entrega ul li h6 {
    margin-bottom: 2px;
  }
  #footer .entrega ul li .img {
    margin: auto;
    width: 25px;
  }
  #footer .entrega ul li .img img {
    width: 100%;
  }
  #footer .entrega ul li div {
    width: 225px;
  }
  #footer .entrega ul li div .text {
    font-size: 12px;
    line-height: 15px;
  }
  #footer .novidades {
    padding: 18px 0 0 0;
    border-width: 3px;
  }
  #footer .novidades h5 {
    padding: 0 29px;
  }
  #footer .novidades .form-footer {
    padding: 0 29px 65px 29px;
  }
  #footer .novidades .form-footer button {
    font-size: 14px;
  }
  #footer .novidades .redes-sociais {
    margin-top: 50px;
    padding-bottom: 28px;
  }
  #footer .novidades .redes-sociais ul li {
    width: 34px;
    height: 21px;
    margin: 0 5px;
  }
  #footer .novidades .redes-sociais ul li:first-child {
    margin: 0 5px 0 0;
  }
  #footer .novidades .redes-sociais ul li a {
    width: 34px;
    height: 21px;
    background-size: 170px auto;
  }
  #footer .novidades .redes-sociais ul li a.twitter {
    background-position: -34px 0;
  }
  #footer .novidades .redes-sociais ul li a.instagram {
    background-position: -68px 0;
  }
  #footer .novidades .redes-sociais ul li a.youtube {
    background-position: -102px 0;
  }
  #footer .novidades .redes-sociais ul li a.tripadvisor {
    background-position: -136px 0;
  }
  .pulse-button::before,
  .pulse-button::after {
    content: '';
  }
  .rodape {
    margin: 13px 0;
  }
  .rodape p {
    font-size: 8px;
  }
  footer.container {
    width: 90vw;
  }
  .footer-institucional {
    margin: 24px 0;
  }
  .footer-institucional > .menu-footer {
    list-style: none;
    padding-left: 0;
    columns: 2;
  }
  .footer-institucional > .menu-footer >  li a {
    height: 40px;
    line-height: 20px;
    padding: 12px 40px 8px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 12px;
    color: #888;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    position: relative;
  }
  .footer-institucional > .menu-footer >  li a:after {
    width: 20px;
    height: 2px;
    background: #912800;
    content: '';
    margin-top: -1px;
    top: 50%;
    left: 12px;
    position: absolute;
  }

  .filter-align {
    display: flex;
    justify-content: space-around;
  }

  #produtos-detalhes {
    margin-top: 20px;
  }
}